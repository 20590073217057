import React, { Component } from "react";

class Socials extends Component {
  render() {
    return (
      <div className={"socials " + (this.props.mobileSml ? "mobile-sml" : "")}>
        <p className="title">SOCIALS</p>
        <a
          href="https://linkedin.com/company/koji-capital/about/"
          target="_blank"
          rel="noreferrer"
        >
          LinkedIn
        </a>
        <a
          href="https://twitter.com/koji_capital"
          target="_blank"
          rel="noreferrer"
        >
          Twitter
        </a>
        <a href="https://discord.gg/sTya2MY7qu" target="_blank" rel="noreferrer">
          Discord
        </a>
        <a
          href="https://www.youtube.com/channel/UCUrLZv1iBoyX78kFHYNsMZA"
          target="_blank"
          rel="noreferrer"
        >
          YouTube
        </a>
      </div>
    );
  }
}

export default Socials;
